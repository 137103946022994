import { storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import renderRichText from '@/lib/render-rich-text'

const RichText = ({ blok, backgroundColor, options }) => {
  return (
    <Stack {...storyblokEditable(blok)} spacing={2.25}>
      {renderRichText({
        document: blok?.rich_text,
        options: { ...options, checkmark: blok?.checkmark },
        backgroundColor,
      })}
    </Stack>
  )
}

export default RichText
