import Typography from '../Typography'
import Link from '../Link'
import colors from '../../theme/colors'

const SkipLink = () => {
  return (
    <Link
      href="#content"
      rel="noopener noreferrer"
      sx={{
        opacity: 0,
        zIndex: -999,
        position: 'absolute',
        '&:focus': {
          top: '20px',
          left: '15px',
          padding: '8px 16px',
          borderRadius: '8px',
          transition: 'all 150ms',
          textDecoration: 'none',
          opacity: 1,
          backgroundColor: colors.primary[500],
          color: 'white',
          zIndex: 9999999999999,
        },
      }}
    >
      <Typography>Skip to main content</Typography>
    </Link>
  )
}

export default SkipLink
