import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import translateSectionStyles from '@/lib/translate-section-styles'

const BasicContent = ({ blok, rels, headingLevel }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const align = blok?.left_aligned ? 'flex-start' : 'center'
  const alignLeft = align === 'flex-start'

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles}>
      <Container constraint="lg">
        <Container leftAlign={alignLeft} constraint={alignLeft ? 'sm' : 'md'}>
          <Stack spacing={5}>
            {blok?.text_content?.map((currentTextContent) => (
              <StoryblokServerComponent
                key={currentTextContent?._uid}
                blok={currentTextContent}
                rels={rels}
                alignItems={align}
                headingLevel={headingLevel}
                backgroundColor={sectionStyles?.backgroundColor}
              />
            ))}

            {blok?.accordion?.map((currentAccordion) => (
              <StoryblokServerComponent
                key={currentAccordion?._uid}
                blok={currentAccordion}
                rels={rels}
                backgroundColor={sectionStyles?.backgroundColor}
              />
            ))}
          </Stack>
        </Container>
      </Container>
    </Section>
  )
}

export default BasicContent
