import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import isValidSvg from '@/lib/is-valid-svg'

const Svg = ({ blok }) => {
  const triggerBlok = blok?.trigger?.[0]
  const svgContent = blok?.svg

  const FinalSvg = (
    <Box
      {...storyblokEditable(blok)}
      className="blok-svg"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          maxWidth: '100%',
          height: 'auto',
        },
      }}
      dangerouslySetInnerHTML={{ __html: isValidSvg(svgContent) ? svgContent : '' }}
    />
  )

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalSvg} /> : FinalSvg
}

export default Svg
