import NextLink from 'next/link'
import Button from '../Button'

const LinkButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} component={NextLink}>
      {children}
    </Button>
  )
}

export default LinkButton
