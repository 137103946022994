/**
 * @param {object} storyblokSectionStyles - object of section styles from Storyblok Section Styles Blok
 * @returns {object} - object of section styles for use in @audioeye-marketing/ui Section component
 */
const translateSectionStyles = (storyblokSectionStyles) => {
  return {
    backgroundColor: storyblokSectionStyles?.background_color?.value || '#FFFFFF',
    padding: storyblokSectionStyles?.padding || 'lg',
    collapseTopPadding: storyblokSectionStyles?.collapse_top_padding || false,
    collapseBottomPadding: storyblokSectionStyles?.collapse_bottom_padding || false,
  }
}

export default translateSectionStyles
