import dynamic from 'next/dynamic'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Skeleton from '@audioeye-marketing/ui/src/components/Skeleton'
import logger from '@/lib/logger'

const Fallback = ({ height, width, noSkeleton }) => (
  <Box sx={{ width, height }}>{Boolean(!noSkeleton) && <Skeleton width={width} height={height} />}</Box>
)

const withFallback = (FallbackComponent, props) => {
  const Component = () => <FallbackComponent {...props} />
  Component.displayName = `WithFallback(${FallbackComponent.displayName || FallbackComponent.name || 'Component'})`
  return Component
}

const Icon = ({ blok, height = 40, width = 40 }) => {
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const iconName = blok?.icon?.icon
  const NonLoadingFallback = withFallback(Fallback, { height, width, noSkeleton: true })
  const LoadingFallback = withFallback(Fallback, { height, width })

  const DynamicIcon = dynamic(
    async () => {
      try {
        const icons = await import('@audioeye/ui-icons/Line')
        if (iconName in icons) {
          return icons[iconName]
        }

        logger.warn(`Icon "${iconName}" not found, using fallback.`)
        return NonLoadingFallback
      } catch (error) {
        logger.error('Error loading icons:', error)
        return NonLoadingFallback
      }
    },
    {
      loading: LoadingFallback,
    }
  )

  const FinalIcon = blok?.icon ? (
    <Box
      {...storyblokEditable(blok)}
      className="blok-icon"
      sx={{
        cursor: trigger ? 'pointer' : 'default',
        width,
        height,
        path: {
          strokeWidth: '1px',
        },
      }}
    >
      <DynamicIcon height={height} width={width} />
    </Box>
  ) : null

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalIcon} /> : FinalIcon
}

export default Icon
