/**
 * @param {string} variant - string representing the button variant
 * @return {object} - string representing the variant to represent in Joy UI
 */
const determineButtonVariant = ({ variant }) => {
  switch (variant) {
    case 'primary':
      return 'solid'
    case 'secondary':
      return 'outlined'
    case 'tertiary':
      return 'plain'
    default:
      return 'solid'
  }
}

export default determineButtonVariant
