import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import TabList from '@audioeye-marketing/ui/src/components/TabList'
import Tabs from '@audioeye-marketing/ui/src/components/Tabs'
import Tab from '@audioeye-marketing/ui/src/components/Tab'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import determineTabColor from '@/lib/determine-tab-color'
import translateSectionStyles from '@/lib/translate-section-styles'

// TODO: mobile responsiveness, vertical vs horizontal
const TabbedContent = ({ blok, headingLevel, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const color = determineTabColor({ backgroundColor: sectionStyles?.backgroundColor })

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center">
      <Container constraint="lg">
        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokServerComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            alignItems={blok?.left_aligned ? 'flex-start' : 'center'}
            backgroundColor={sectionStyles?.backgroundColor}
            headingLevel={headingLevel}
          />
        ))}
      </Container>
      <Container constraint="lg" mt={{ xs: 3.75, md: 8 }}>
        <Tabs
          size="lg"
          orientation={blok?.variant || 'vertical'}
          sx={{
            backgroundColor: `${sectionStyles?.backgroundColor}`,
            justifyContent: 'space-between',
          }}
        >
          <TabList
            disableUnderline
            sx={{
              flex: '0 0 calc((100% / 12) * 4)',
              alignSelf: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Stack spacing={1.25}>
              <Typography
                level="title-lg"
                // * Needed for Section.jsx to color determine the svg image
                className="blok-tab-heading"
                sx={{ textAlign: { xs: 'center', md: 'left' } }}
              >
                {blok?.tab_list_heading}
              </Typography>
              <Stack
                spacing={2}
                direction={{ xs: 'row', md: blok?.variant === 'vertical' ? 'column' : 'row' }}
                sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
              >
                {blok?.tabs?.map((currentTab) => {
                  return (
                    <Tab
                      {...storyblokEditable(currentTab)}
                      key={currentTab?._uid}
                      color={color}
                      variant="plain"
                      indicatorPlacement="bottom"
                      sx={{ textAlign: { xs: 'center', md: 'left' } }}
                    >
                      {currentTab?.tab_heading}
                    </Tab>
                  )
                })}
              </Stack>
            </Stack>
          </TabList>
          <Box sx={{ flex: '0 0 calc((100% / 12) * 7)' }}>
            {blok?.tabs?.map((currentTab, index) => {
              return (
                <StoryblokServerComponent
                  key={currentTab?._uid}
                  blok={currentTab}
                  tabHeading={currentTab?.tab_heading}
                  value={index}
                  headingLevel={headingLevel}
                  backgroundColor={sectionStyles?.backgroundColor}
                  imagePriority={imagePriority}
                />
              )
            })}
          </Box>
        </Tabs>
      </Container>
    </Section>
  )
}

export default TabbedContent
