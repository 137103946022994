// Necessary if using App Router to ensure this file runs on the client
'use client'

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_WWW_DATADOG_APPLICATION_ID,
  clientToken: process.env.NEXT_PUBLIC_WWW_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'www.audioeye.com',
  env: process.env.NEXT_PUBLIC_WWW_APP_ENV === 'production' ? 'prod' : 'beta',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

const Datadog = () => {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}

export default Datadog
