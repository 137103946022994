import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIGrid from '@audioeye-marketing/ui/src/components/Grid'

const Grid = ({ blok, backgroundColor, imagePriority }) => {
  const gridSx = {}

  if (blok?.columns === 'none') {
    // Takes the width of the md Section container minus the padding of spacing={4}
    gridSx.width = `${864 / blok?.items?.length - 16}px`
    gridSx.display = 'flex'
    gridSx.justifyContent = 'center'
  }

  const baseGridProps = blok?.columns !== 'none' ? { xs: 12, md: 12 / blok?.columns } : {}

  return (
    <UIGrid {...storyblokEditable(blok)} container spacing={4} justifyContent={blok?.justify_content}>
      {blok?.items?.map((currentItem, index) => {
        const remainingItems = blok?.items?.length % blok?.columns
        const finalGridProps = { ...baseGridProps }

        if (blok?.reverse_columns) {
          if (remainingItems > 0 && index < remainingItems) {
            finalGridProps.md = 12 / remainingItems
          }
        } else if (blok?.bento_box && remainingItems > 0 && index >= blok?.items?.length - remainingItems) {
          finalGridProps.md = 12 / remainingItems
        }

        return (
          <UIGrid key={currentItem?._uid} display="flex" justifyContent="center" sx={gridSx} {...finalGridProps}>
            <StoryblokServerComponent
              blok={currentItem}
              backgroundColor={backgroundColor}
              imagePriority={imagePriority}
            />
          </UIGrid>
        )
      })}
    </UIGrid>
  )
}

export default Grid
