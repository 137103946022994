import { StoryblokServerComponent } from '@storyblok/react/rsc'
import UIMenu from '@audioeye-marketing/ui/src/components/Menu'
import Dropdown from '@audioeye-marketing/ui/src/components/Dropdown'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
// import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import colors from '@audioeye-marketing/ui/src/theme/colors'

const MegaMenu = ({ blok, trigger, rels }) => {
  return (
    <Dropdown open={blok?._uid === '2a6f0e1d-ef3f-4485-b688-109fbea54e01'}>
      {trigger}
      <UIMenu variant="plain" sx={{ borderRadius: 8, maxWidth: '1160px', padding: 0 }}>
        <Grid container sx={{ flexGrow: 1 }}>
          {/* {blok?.groups?.map((group) => {
            const gridProps = {
              md: 6,
              lg: 4,
              xl: 2,
            }

            if (group?.display_as_row) {
              gridProps.md = 12
              gridProps.lg = 12
              gridProps.xl = 12
            }

            console.log(group.component)
            return (
              <Grid
                key={group?._uid}
                {...gridProps}
                sx={{
                  flex: 'auto !important',
                  minWidth: '254px',
                  maxWidth: '100%',
                }}
              >
                <StoryblokServerComponent blok={group} key={group?._uid} />
              </Grid>
            )
          })} */}
          <Grid size={4} sx={{ backgroundColor: 'red' }}>
            <Box
              pt={2}
              pb={2}
              pl={4}
              pr={4}
              sx={{
                borderRight: '1px solid var(--joy-palette-divider)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              {blok?.cta.map((currentCta) => (
                <StoryblokServerComponent blok={currentCta} key={currentCta?._uid} rels={rels} />
              ))}
            </Box>
          </Grid>
          <Grid size={8}>
            <Box
              pt={2}
              pb={2}
              pl={5.5}
              pr={5.5}
              sx={{
                backgroundColor: colors.neutral[100],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {blok?.featured_item?.map((featuredItem) => (
                <StoryblokServerComponent blok={featuredItem} key={featuredItem?._uid} horizontalForm />
              ))}
            </Box>
          </Grid>
        </Grid>
      </UIMenu>
    </Dropdown>
  )
}

export default MegaMenu
