import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import getResolvedStoryblokStory from '@/lib/get-resolved-storyblok-story'

const GlobalButton = ({ blok, rels, backgroundColor }) => {
  const content = {
    ...getResolvedStoryblokStory({ item: blok?.button, rels }),
    variant: blok?.variant?.length > 0 ? blok?.variant : blok?.button?.content?.variant,
  }

  return <StoryblokServerComponent {...storyblokEditable} blok={content} backgroundColor={backgroundColor} />
}

export default GlobalButton
