import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import getResolvedStoryblokStory from '@/lib/get-resolved-storyblok-story'

const Frame = ({ blok, children, rels }) => {
  const header = getResolvedStoryblokStory({ item: blok?.header, rels })
  const footer = getResolvedStoryblokStory({ item: blok?.footer, rels })

  return (
    <div {...storyblokEditable(blok)}>
      {Boolean(header) && <StoryblokServerComponent blok={header} rels={rels} />}
      {/* ID #content for SkipLink.jsx */}
      {/* TODO: figure out a way to get 118px differently */}
      <Box component="main" id="content" role="main" tabIndex={-1}>
        {blok?.before_content?.map((currentSection, index) => (
          <StoryblokServerComponent
            key={currentSection?._uid}
            blok={currentSection}
            rels={rels}
            headingLevel={index === 0 ? 1 : 2}
            imagePriority={index === 0 || index === 1}
          />
        ))}

        {children}

        {blok?.after_content?.map((currentSection) => (
          <StoryblokServerComponent key={currentSection?._uid} blok={currentSection} rels={rels} headingLevel={2} />
        ))}
      </Box>
      {Boolean(footer) && <StoryblokServerComponent blok={footer} rels={rels} />}
    </div>
  )
}

export default Frame
