import Image from 'next/image'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import AudioEyeLogo from '@/assets/logos/audioeye-logo-black-purple-symbol.svg'

const Header = ({ blok, rels }) => {
  const logoLink = blok?.logo_link[0]
  const FinalLogo = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image priority src={AudioEyeLogo} width={151} height={36} alt="audio eye logo" />
    </Box>
  )

  return (
    <Box {...storyblokEditable(blok)} component="header">
      {blok?.utility_bar.map((utilityBar) => {
        return <StoryblokServerComponent key={utilityBar?._uid} blok={utilityBar} rels={rels} />
      })}
      <Box
        sx={{
          padding: { xs: '1.75rem 1.5rem', md: '1.25rem 5rem' },
          borderTop: '1px solid var(--joy-palette-divider)',
          borderBottom: '1px solid var(--joy-palette-divider)',
        }}
      >
        <Stack direction="row" spacing={8} justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={{ xs: 4, lg: 6 }}>
            {logoLink ? <StoryblokServerComponent blok={logoLink} trigger={FinalLogo} /> : FinalLogo}

            <Stack direction="row" spacing={3}>
              {blok?.navigation_items?.map((navigationItem) => {
                return (
                  <StoryblokServerComponent
                    key={navigationItem?._uid}
                    blok={navigationItem}
                    color="neutral"
                    rels={rels}
                  />
                )
              })}
            </Stack>
          </Stack>

          <Box>
            {blok?.cta?.map((cta) => {
              return <StoryblokServerComponent key={cta?._uid} blok={cta} rels={rels} />
            })}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default Header
