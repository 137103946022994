import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import translateSectionStyles from '@/lib/translate-section-styles'

const LogoBanner = ({ blok, rels }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])

  const heading = blok?.heading ? (
    <Typography
      level={blok?.variant === 'two-column' ? 'h4' : 'title-lg'}
      sx={{
        textAlign:
          blok?.variant === 'default'
            ? 'center'
            : blok?.variant === 'two-column'
              ? { xs: 'center', lg: 'left' }
              : 'left',
        flex: 3,
      }}
    >
      {blok?.heading}
    </Typography>
  ) : null

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center">
      <Container constraint="lg">
        <Stack spacing={3}>
          {blok?.variant !== 'two-column' && heading}
          <Stack
            spacing={{ xs: blok?.variant === 'two-column' ? 3 : 0, md: 8 }}
            direction={{ xs: blok?.variant === 'two-column' ? 'column' : 'row', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            {blok?.variant === 'two-column' && heading}
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 4, md: 4 }}
              sx={{
                flexWrap: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: blok?.variant === 'left-aligned' ? { xs: 'flex-start', sm: 'center' } : 'center',
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                flex: 9,
              }}
            >
              {blok?.logos?.map((currentLogo, index) => {
                const firstLogo = index === 0
                const lastLogo = index === blok?.logos?.length - 1
                const paddingLeft = firstLogo ? 0 : undefined
                const paddingRight = lastLogo ? 0 : undefined
                const additionalSx =
                  blok?.variant === 'left-aligned' && firstLogo
                    ? {
                        '.blok-svg': {
                          justifyContent: 'flex-start',
                        },
                      }
                    : undefined

                return (
                  <Grid
                    key={currentLogo?.content?._uid}
                    sx={{
                      paddingLeft,
                      paddingRight,
                      flex: 1,
                      ...additionalSx,
                    }}
                  >
                    <StoryblokServerComponent blok={currentLogo?.content} rels={rels} />
                  </Grid>
                )
              })}
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Section>
  )
}

export default LogoBanner
