'use client'

import React from 'react'
import { useServerInsertedHTML } from 'next/navigation'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from '@mui/joy/styles'
import theme from '@audioeye-marketing/ui/src/theme/joy'

// * Implementation below was pulled directly from the Joy Docs:
// * https://mui.com/joy-ui/integrations/next-js-app-router/
// * https://mui.com/joy-ui/integrations/material-ui/
const JoyAndMaterialProvider = (props) => {
  const { options, children } = props

  const [{ cache, flush }] = React.useState(() => {
    const cache = createCache(options)
    cache.compat = true
    const prevInsert = cache.insert
    let inserted = []
    cache.insert = (...args) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  useServerInsertedHTML(() => {
    const names = flush()
    if (names.length === 0) {
      return null
    }
    let styles = ''
    for (const name of names) {
      styles += cache.inserted[name]
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    )
  })

  const extendedTheme = extendTheme(theme)

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={{ [MATERIAL_THEME_ID]: extendedTheme }}>
        <JoyCssVarsProvider theme={extendedTheme}>
          <CssBaseline />
          {children}
        </JoyCssVarsProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default JoyAndMaterialProvider
