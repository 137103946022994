import Box from '../Box'
import determinePadding from '../../lib/determine-padding'
import determineColors from '../../lib/determine-colors'
import colors from '../../theme/colors'

const Section = ({
  children,
  padding,
  collapseTopPadding,
  collapseBottomPadding,
  collapseLeftPadding,
  collapseRightPadding,
  collapseRightPaddingMediumUp,
  backgroundColor,
  ...rest
}) => {
  const calculatedPadding = determinePadding({
    padding,
    collapseTopPadding,
    collapseBottomPadding,
    collapseLeftPadding,
    collapseRightPadding,
    collapseRightPaddingMediumUp,
  })

  const calculatedColors = determineColors({
    backgroundColor,
  })

  return (
    <Box
      component="section"
      backgroundColor={backgroundColor}
      sx={{
        ...rest.sx,
        position: 'relative', // Necessary for things like the textured image in Split Content
        backgroundColor: backgroundColor || colors.common.white,
        paddingTop: calculatedPadding.paddingTop,
        paddingBottom: calculatedPadding.paddingBottom,
        paddingLeft: calculatedPadding.paddingLeft,
        paddingRight: calculatedPadding.paddingRight,
        // * Typography
        '.MuiTypography-root': {
          color: `${calculatedColors?.Typography?.text} !important`,
        },
        '.MuiTypography-eyebrow': {
          // After .MuiTypography-root so it can override it
          color: `${calculatedColors?.Typography?.eyebrow} !important`,
        },
        // * List Item
        '.MuiListItemDecorator-root': {
          color: `${calculatedColors?.ListItem?.listItemDecorator} !important`,
        },
        // * Quote
        '.SectionQuote .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.displayMd} !important`,
        },
        '.SectionQuote .MuiCard-root .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.Card?.displayMd} !important`,
        },
        // * SVG
        '.blok-svg svg': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-svg path': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-icon svg': {
          stroke: calculatedColors?.Svg?.icon,
        },
        '.blok-icon path': {
          stroke: calculatedColors?.Svg?.icon,
        },
        // * Card
        '.MuiCard-root': {
          backgroundColor: `${calculatedColors?.Card?.backgroundColor} !important`,
          border: `1px solid ${calculatedColors.Card.border}`,
        },
        '.MuiCard-root .MuiTypography-root': {
          color: `${calculatedColors?.Card?.text} !important`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger': {
          '--_Input-focusedHighlight': `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger .MuiInput-input': {
          color: `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiFormHelperText-root': {
          color: `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .blok-icon svg': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        '.MuiCard-root .blok-icon path': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        // * Card With Form
        '.MuiCard-root.CardWithForm': {
          backgroundColor: `${calculatedColors?.CardWithForm?.backgroundColor} !important`,
        },
        // * Footer
        '&:is(footer) .intro-text': {
          color: `${calculatedColors?.Footer?.introText} !important`,
        },
        '&:is(footer) .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        '&:is(footer) .MuiCard-root .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        // * Accordion
        '.MuiAccordion-root .MuiAccordionSummary-button': {
          color: `${calculatedColors?.Accordion?.summaryText} !important`,
        },
        '.MuiAccordion-root .MuiAccordionSummary-indicator svg': {
          color: `${calculatedColors?.Accordion?.icon} !important`,
        },
        '.MuiAccordionSummary-button:not(.Mui-selected, [aria-selected="true"]):hover': {
          backgroundColor: `${calculatedColors?.Accordion?.hoverBg} !important`,
        },
        '.MuiAccordion-root .MuiAccordionDetails-content .MuiTypography-root': {
          color: `${calculatedColors?.Accordion?.detailsText} !important`,
        },
        // * Tabs
        '.MuiTabs-root .blok-tab-heading': {
          color: `${calculatedColors?.Tabs?.heading} !important`,
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Section
