import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Accordion from '@audioeye-marketing/ui/src/components/Accordion'
import AccordionSummary from '@audioeye-marketing/ui/src/components/AccordionSummary'
import AccordionDetails from '@audioeye-marketing/ui/src/components/AccordionDetails'

const AccordionItem = ({ blok, backgroundColor }) => {
  return (
    <Accordion {...storyblokEditable(blok)}>
      <AccordionSummary>{blok?.summary}</AccordionSummary>
      <AccordionDetails>
        {blok?.content?.map((currentRichText) => (
          <StoryblokServerComponent
            key={currentRichText?._uid}
            blok={currentRichText}
            options={{ NODE_PARAGRAPH: { level: 'body-md' } }}
            backgroundColor={backgroundColor}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem
