import Image from 'next/image'
import BackgroundTexture from '@/assets/components/background-texture.png'

const TexturedImage = ({ position }) => {
  return (
    <Image
      src={BackgroundTexture}
      alt=""
      role="presentation"
      width="350"
      height="180"
      style={{
        position: 'absolute',
        top: 0,
        [position || 'right']: 0,
        mixBlendMode: 'color-dodge',
      }}
    />
  )
}

export default TexturedImage
