import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Link from '@audioeye-marketing/ui/src/components/Link'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import determineLinkColor from '@/lib/determine-link-color'

const TextLink = ({ blok, backgroundColor, textAlign, level, hoverUnderline }) => {
  const triggerBlok = blok?.trigger?.[0]
  const color = determineLinkColor({ backgroundColor })
  const FinalText = (
    <Typography {...storyblokEditable(blok)} component="span" level={level}>
      {blok?.text}
    </Typography>
  )

  return triggerBlok ? (
    <StoryblokServerComponent
      blok={triggerBlok}
      triggerComponent={
        <Link {...storyblokEditable(blok)} color={color} textAlign={textAlign} hoverUnderline={hoverUnderline}>
          <Typography component="span" level={level}>
            {blok?.text}
          </Typography>
        </Link>
      }
    />
  ) : (
    FinalText
  )
}

export default TextLink
