import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const ButtonGroup = ({ blok, rels, backgroundColor, fullWidth }) => {
  return (
    <Stack
      {...storyblokEditable(blok)}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={3}
      sx={{ width: fullWidth ? '100%' : 'auto' }}
    >
      {blok?.buttons?.map((currentButton) => (
        <StoryblokServerComponent
          key={currentButton?._uid}
          blok={currentButton}
          rels={rels}
          backgroundColor={backgroundColor}
          fullWidth={fullWidth}
        />
      ))}
    </Stack>
  )
}

export default ButtonGroup
