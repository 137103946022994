import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import TabPanel from '@audioeye-marketing/ui/src/components/TabPanel'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const Tab = ({ blok, value, tabHeading, headingLevel, backgroundColor, imagePriority, ...rest }) => {
  return (
    <TabPanel {...storyblokEditable(blok)} value={value} {...rest} sx={{ padding: 0 }}>
      <Stack spacing={4} sx={{ textAlign: 'left' }} alignItems="center">
        {blok?.media?.map((currentMedia) => {
          return (
            <StoryblokServerComponent
              key={currentMedia?._uid}
              blok={currentMedia}
              headingLevel={headingLevel}
              backgroundColor={backgroundColor}
              imagePriority={imagePriority}
              maxWidth="100%"
            />
          )
        })}

        <Box>
          <Stack spacing={1.25}>
            {Boolean(tabHeading) && (
              <Typography level="title-lg" sx={{ textAlign: 'left' }}>
                {tabHeading}
              </Typography>
            )}
            {blok?.text?.map((currentText) => (
              <StoryblokServerComponent key={currentText?._uid} blok={currentText} backgroundColor={backgroundColor} />
            ))}
          </Stack>
        </Box>
      </Stack>
    </TabPanel>
  )
}

export default Tab
