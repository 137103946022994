import colors from '@audioeye-marketing/ui/src/theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @return {string} - string representing the link color
 */
const determineLinkColor = ({ backgroundColor }) => {
  const linkColors = {
    [colors.neutral[50]]: 'primary',
    [colors.common.white]: 'primary',
    [colors.common.black]: 'light',
    [colors.primary[900]]: 'light',
    [colors.secondary[900]]: 'light',
    [colors.tertiary[900]]: 'light',
    [colors.info[900]]: 'light',
  }

  return linkColors[backgroundColor] || linkColors[colors.common.white]
}

export default determineLinkColor
