import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Share03 from '@audioeye/ui-icons/Line/General/Share03'
import ChevronDown from '@audioeye/ui-icons/Line/Arrows/ChevronDown'
import UIButton from '@audioeye-marketing/ui/src/components/Button'
import LinkButton from '@audioeye-marketing/ui/src/components/LinkButton'
import MenuButton from '@audioeye-marketing/ui/src/components/MenuButton'
import determineButtonColor from '@/lib/determine-button-color'
import determineButtonVariant from '@/lib/determine-button-variant'

const Button = ({ blok, backgroundColor, fullWidth, size, color }) => {
  const buttonColor = color || determineButtonColor({ backgroundColor })
  const variant = determineButtonVariant({ variant: blok?.variant })
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const isMenu = trigger?.component === 'Menu' || trigger?.component === 'Mega Menu'
  const ButtonType = trigger?.component === 'Link' ? LinkButton : isMenu ? MenuButton : UIButton
  const buttonProps = isMenu ? {} : { fullWidth }
  const FinalButton = (
    <ButtonType
      {...storyblokEditable(blok)}
      {...buttonProps}
      color={buttonColor}
      size={size || 'lg'}
      variant={variant}
      endDecorator={isMenu ? <ChevronDown /> : trigger?.link?.target === '_blank' ? <Share03 /> : null}
    >
      {blok?.text}
    </ButtonType>
  )

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalButton} /> : FinalButton
}

export default Button
