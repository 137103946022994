import { StoryblokServerComponent } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const MegaMenuGroup = ({ blok }) => {
  const sxProps = {}

  if (blok?.divider) {
    if (blok?.display_as_row) {
      sxProps.borderBottom = '1px solid var(--joy-palette-divider)'
    } else {
      sxProps.borderRight = '1px solid var(--joy-palette-divider)'
    }
  }

  return (
    <Stack spacing={2.5} sx={sxProps}>
      <Typography>{blok?.title}</Typography>
      <Stack direction={blok?.display_as_row ? 'row' : 'column'} spacing={blok?.display_as_row ? 9.5 : 1}>
        {blok?.items?.map((item) => {
          return <StoryblokServerComponent key={item?._uid} blok={item} />
        })}
      </Stack>
    </Stack>
  )
}

export default MegaMenuGroup
