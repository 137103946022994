import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_lib/datadog.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_lib/hubspot-tracker.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_lib/utm-params-tracker.js");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/opt/build/repo/apps/www.audioeye.com/app/_providers/App.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_providers/JoyAndMaterial.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_providers/PostHog.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_providers/ReactQuery.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/www.audioeye.com/app/_providers/Storyblok.jsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Schibsted_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-schibsted-grotesk\",\"display\":\"swap\"}],\"variableName\":\"schibstedGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"500\"],\"variable\":\"--font-dm-mono\",\"display\":\"swap\"}],\"variableName\":\"dmMono\"}");
