import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import UICard from '@audioeye-marketing/ui/src/components/Card'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'

const Card = ({ blok }) => {
  const Wrapper = blok?.card ? UICard : Box
  const wrapperProps = blok?.card ? { noBorderRadius: true, variant: 'plain' } : {}

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      {...wrapperProps}
      sx={{
        // * Done this way instead of size="lg" for Card so Box matches Card padding
        padding: 4,
        width: '100%',
      }}
    >
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={2}>
          {Boolean(blok?.icon?.length > 0) &&
            blok?.icon?.map((currentIcon) => <StoryblokServerComponent key={currentIcon?._uid} blok={currentIcon} />)}
          {Boolean(blok?.heading) && <Typography level={blok?.heading_level || 'title-lg'}>{blok?.heading}</Typography>}
          {blok?.text?.map((currentRichText) => (
            <StoryblokServerComponent key={currentRichText._uid} blok={currentRichText} />
          ))}
        </Stack>
        {blok?.cta?.map((currentCta) => (
          <StoryblokServerComponent key={currentCta?._uid} blok={currentCta} />
        ))}
      </Stack>
    </Wrapper>
  )
}

export default Card
