import { StoryblokServerComponent } from '@storyblok/react/rsc'

const Trigger = ({ blok, triggerComponent }) => {
  return blok?.trigger?.[0] ? (
    <StoryblokServerComponent blok={blok?.trigger?.[0]} trigger={triggerComponent} />
  ) : (
    triggerComponent
  )
}

export default Trigger
