import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Section from '@audioeye-marketing/ui/src/components/Section'
import AudioEyeSystemStatus from '@/components/AudioEyeSystemStatus'
import LogoAndSocialLinks from '@/components/LogoAndSocialLinks'

const Footer = ({ blok, rels }) => {
  const backgroundColor = colors.secondary[900]
  const hasIntroBanner =
    (Boolean(blok?.intro_text) && Boolean(blok?.intro_link)) || Boolean(blok?.intro_cta?.length > 0)
  const hasLists = blok?.lists?.length > 0

  return (
    <Section {...storyblokEditable(blok)} backgroundColor={backgroundColor} component="footer">
      <Stack spacing={{ xs: 6, md: 8 }}>
        {/* Intro Banner */}
        {Boolean(hasIntroBanner) && (
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={3}>
            {Boolean(blok?.intro_text) && Boolean(blok?.intro_link) && (
              <Typography level="h4">
                {/* className needed for styles in Section.jsx */}
                <Box component="span" className="intro-text">
                  {blok?.intro_text}
                </Box>{' '}
                {Boolean(blok?.intro_link?.length > 0) && (
                  <StoryblokServerComponent
                    blok={blok?.intro_link[0]}
                    rels={rels}
                    backgroundColor={backgroundColor}
                    hoverUnderline
                  />
                )}
              </Typography>
            )}
            <Stack direction="row" justifyContent="space-between">
              {blok?.intro_cta?.map((currentCta) => (
                <StoryblokServerComponent
                  key={blok?._uid}
                  blok={currentCta}
                  rels={rels}
                  backgroundColor={backgroundColor}
                  fullWidth
                />
              ))}
            </Stack>
          </Stack>
        )}
        {/* Lists */}
        {Boolean(hasLists) && (
          <Grid container spacing={2.25} display="flex" justifyContent="flex-start">
            {blok?.lists?.map((currentList) => {
              return (
                <Grid
                  key={currentList?._uid}
                  xs={12}
                  md={6}
                  lg={4}
                  xl={2}
                  sx={{
                    flex: 'auto !important',
                    minWidth: '254px',
                    maxWidth: '100%',
                  }}
                >
                  <StoryblokServerComponent blok={currentList} rels={rels} backgroundColor={backgroundColor} />
                </Grid>
              )
            })}
          </Grid>
        )}
        {/* Logo And Social Links */}
        <LogoAndSocialLinks backgroundColor={backgroundColor} />
        <Stack spacing={2}>
          {/* Legal */}
          <Container constraint="md" leftAlign>
            {blok?.legal?.map((currentRichText) => (
              <StoryblokServerComponent
                key={blok?._uid}
                blok={currentRichText}
                rels={rels}
                backgroundColor={backgroundColor}
                options={{
                  NODE_PARAGRAPH: {
                    textAlign: { xs: 'center', md: 'left' },
                    level: 'body-sm',
                  },
                }}
              />
            ))}
          </Container>
          {/* Regulatory Links */}
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={2.5}>
            <Grid container spacing={2.25} display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
              {blok?.regulatory_links?.map((currentLink, index) => {
                const additionalProps = index === 0 ? { paddingLeft: { md: 0 } } : {}

                return (
                  <Grid
                    sx={{
                      maxWidth: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end',
                      display: 'flex',
                    }}
                    key={currentLink?._uid}
                    {...additionalProps}
                  >
                    <StoryblokServerComponent
                      blok={currentLink}
                      rels={rels}
                      backgroundColor={backgroundColor}
                      hoverUnderline
                      level="label-sm"
                    />
                  </Grid>
                )
              })}
            </Grid>
            {/* AudioEye System Status */}
            <AudioEyeSystemStatus alignItems={{ xs: 'center', md: 'flex-end' }} justifyContent="flex-end" />
          </Stack>
        </Stack>
      </Stack>
    </Section>
  )
}

export default Footer
