import React from 'react'
import NextLink from 'next/link'

const Link = ({ blok, trigger }) => {
  let href = '/'

  if (blok?.link?.linktype === 'story') {
    href = blok?.link?.cached_url === 'home' ? '/' : `/${blok?.link?.cached_url}`
  } else {
    href = blok?.link?.cached_url
  }

  const clonedTrigger = React.cloneElement(trigger, {
    href,
    target: blok?.link?.target,
    // * Necessary for CookieYes. All links will be considered a CookieYes Link if the url is #
    className: Boolean(blok?.link?.cached_url === '#') && 'cky-banner-element',
    component: NextLink,
  })

  return clonedTrigger
}

export default Link
