import Box from '../Box'

const Container = ({ children, constraint, leftAlign, ...rest }) => {
  const sxProps = {
    ...rest.sx,
  }

  if (constraint === 'sm' || constraint === 'md' || constraint === 'lg') {
    sxProps.marginLeft = leftAlign ? 0 : 'auto !important'
    sxProps.marginRight = leftAlign ? 0 : 'auto !important'
    sxProps.maxWidth = constraint === 'sm' ? '674px' : constraint === 'md' ? '900px' : '1200px'
  } else {
    sxProps.maxWidth = { lg: constraint || '100%', xs: '100%' }
    sxProps.margin = 0
  }

  return (
    <Box
      {...rest}
      sx={{
        ...sxProps,
      }}
    >
      {children}
    </Box>
  )
}

export default Container
