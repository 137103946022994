import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Container from '@audioeye-marketing/ui/src/components/Container'

const TextContent = ({ blok, rels, alignItems, headingLevel, backgroundColor, textAlign }) => {
  const alignLeft =
    alignItems === 'flex-start' ||
    (typeof alignItems === 'object' &&
      (alignItems?.xs === 'flex-start' ||
        alignItems?.sm === 'flex-start' ||
        alignItems?.md === 'flex-start' ||
        alignItems?.lg === 'flex-start'))

  return (
    <Box {...storyblokEditable(blok)} sx={{ textAlign: textAlign ? textAlign : alignLeft ? 'left' : 'center' }}>
      <Stack spacing={5} alignItems={alignItems}>
        <Stack spacing={1}>
          {Boolean(blok?.eyebrow) && (
            <Container constraint={alignLeft ? '100%' : 'sm'}>
              <Typography level="eyebrow">{blok?.eyebrow}</Typography>
            </Container>
          )}
          <Stack spacing={3}>
            {Boolean(blok?.heading) && (
              <Container constraint={alignLeft ? '100%' : 'md'}>
                <Typography displayLarge={blok?.large_heading} level={`h${headingLevel}`}>
                  {blok?.heading}
                </Typography>
              </Container>
            )}
            {blok?.text?.map((currentText) => (
              <Container key={blok?._uid} constraint={alignLeft ? '100%' : 'sm'}>
                <StoryblokServerComponent blok={currentText} backgroundColor={backgroundColor} />
              </Container>
            ))}
          </Stack>
        </Stack>
        {blok?.cta?.map((currentCta) => (
          <StoryblokServerComponent key={blok?._uid} blok={currentCta} rels={rels} backgroundColor={backgroundColor} />
        ))}
      </Stack>
    </Box>
  )
}

export default TextContent
