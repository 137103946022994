'use client'

import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_WWW_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_WWW_POSTHOG_HOST,
  })
}

const PostHogProvider = ({ children }) => {
  return <PHProvider client={posthog}>{children}</PHProvider>
}

export default PostHogProvider
