import colors from '../theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @return {object} - string representing the text color for the body and eyebrow
 */
const determineColors = ({ backgroundColor }) => {
  const settings = {
    [colors.neutral[50]]: {
      Typography: {
        eyebrow: colors.secondary[500],
        text: 'inherit',
      },
      ListItem: {
        listItemDecorator: colors.secondary[500],
      },
      Quote: {
        displayMd: colors.secondary[500],
        Card: {
          displayMd: colors.secondary[500],
        },
      },
      Svg: {
        image: colors.common.black,
        icon: colors.common.black,
      },
      Form: {
        Card: {
          backgroundColor: colors.common.white,
        },
      },
      Card: {
        border: 'rgba(var(--joy-palette-neutral-mainChannel, 99 107 116) / 0.2)',
        backgroundColor: colors.common.white,
        text: colors.neutral[900],
        Input: {
          invalid: colors.danger[500],
        },
        Svg: {
          icon: colors.common.black,
        },
      },
      Footer: {
        introText: colors.secondary[500],
        listTitle: colors.secondary[500],
      },
      Accordion: {
        summaryText: 'inherit',
        icon: 'var(--Icon-color)',
        hoverBg: colors.common.white,
        detailsText: 'inherit',
      },
      Tabs: {
        heading: colors.neutral[500],
      },
    },
    [colors.common.white]: {
      Typography: {
        eyebrow: colors.secondary[500],
        text: 'inherit',
      },
      ListItem: {
        listItemDecorator: colors.secondary[500],
      },
      Quote: {
        displayMd: colors.secondary[500],
        Card: {
          displayMd: colors.secondary[500],
        },
      },
      Svg: {
        image: colors.common.black,
        icon: colors.common.black,
      },
      CardWithForm: {
        backgroundColor: colors.neutral[50],
      },
      Card: {
        border: 'rgba(var(--joy-palette-neutral-mainChannel, 99 107 116) / 0.2)',
        backgroundColor: colors.neutral[50],
        text: colors.neutral[900],
        Input: {
          invalid: colors.danger[500],
        },
        Svg: {
          icon: colors.common.black,
        },
      },
      Footer: {
        introText: colors.secondary[500],
        listTitle: colors.secondary[500],
      },
      Accordion: {
        summaryText: 'inherit',
        icon: 'var(--Icon-color)',
        hoverBg: colors.neutral[50],
        detailsText: 'inherit',
      },
      Tabs: {
        heading: colors.neutral[500],
      },
    },
    [colors.common.black]: {
      Typography: {
        eyebrow: colors.common.white,
        text: colors.common.white,
      },
      ListItem: {
        listItemDecorator: colors.common.white,
      },
      Quote: {
        displayMd: colors.common.white,
        Card: {
          displayMd: colors.common.white,
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.common.white,
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: colors.light[900],
        text: colors.common.white,
        Input: {
          invalid: colors.danger[300],
        },
        Svg: {
          icon: colors.common.white,
        },
      },
      Footer: {
        introText: colors.common.white,
        listTitle: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.common.white,
        hoverBg: colors.light[900],
        detailsText: colors.light[100],
      },
      Tabs: {
        heading: colors.common.white,
      },
    },
    [colors.primary[900]]: {
      Typography: {
        eyebrow: colors.primary[300],
        text: colors.common.white,
      },
      ListItem: {
        listItemDecorator: colors.primary[300],
      },
      Quote: {
        displayMd: colors.primary[300],
        Card: {
          displayMd: colors.primary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.primary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: colors.light[900],
        text: colors.common.white,
        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.primary[300],
        },
      },
      Footer: {
        introText: colors.primary[200],
        listTitle: colors.primary[200],
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.primary[300],
        hoverBg: colors.light[900],
        detailsText: colors.light[100],
      },
      Tabs: {
        heading: colors.primary[300],
      },
    },
    [colors.secondary[900]]: {
      Typography: {
        eyebrow: colors.secondary[300],
        text: colors.common.white,
      },
      ListItem: {
        listItemDecorator: colors.secondary[300],
      },
      Quote: {
        displayMd: colors.secondary[300],
        Card: {
          displayMd: colors.secondary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.secondary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: colors.light[900],
        text: colors.common.white,
        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.secondary[300],
        },
      },
      Footer: {
        introText: colors.secondary[200],
        listTitle: colors.secondary[200],
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.secondary[300],
        hoverBg: colors.light[900],
        detailsText: colors.light[100],
      },
      Tabs: {
        heading: colors.secondary[300],
      },
    },
    [colors.tertiary[900]]: {
      Typography: {
        eyebrow: colors.tertiary[300],
        text: colors.common.white,
      },
      ListItem: {
        listItemDecorator: colors.tertiary[300],
      },
      Quote: {
        displayMd: colors.tertiary[300],
        Card: {
          displayMd: colors.tertiary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.tertiary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: colors.light[900],
        text: colors.common.white,
        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.tertiary[300],
        },
      },
      Footer: {
        introText: colors.tertiary[200],
        listTitle: colors.tertiary[200],
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.tertiary[300],
        hoverBg: colors.light[900],
        detailsText: colors.light[100],
      },
      Tabs: {
        heading: colors.tertiary[300],
      },
    },
    [colors.info[900]]: {
      Typography: {
        eyebrow: colors.info[300],
        text: colors.common.white,
      },
      ListItem: {
        listItemDecorator: colors.info[300],
      },
      Quote: {
        displayMd: colors.info[300],
        Card: {
          displayMd: colors.info[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.info[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: colors.light[900],
        text: colors.common.white,
        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.info[300],
        },
      },
      Footer: {
        introText: colors.info[200],
        listTitle: colors.info[200],
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.info[300],
        hoverBg: colors.light[900],
        detailsText: colors.light[100],
      },
      Tabs: {
        heading: colors.info[300],
      },
    },
  }

  return settings[backgroundColor] || settings[colors.neutral[50]]
}

export default determineColors
