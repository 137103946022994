/**
 * @param {string} svgContent - string containing the svg content
 * @return {bool} - is string svg content or not
 */
const isValidSvg = (svgContent) => {
  if (typeof svgContent !== 'string') return false
  const svgTagRegex = /<svg[^>]*>(?:[\s\S]*?)<\/svg>/i
  return svgTagRegex.test(svgContent)
}

export default isValidSvg
