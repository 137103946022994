import chalk from 'chalk'
import dayjs from 'dayjs'

const levels = ['fatal', 'error', 'warn', 'info', 'debug']
const envLevel = process.env.NEXT_PUBLIC_WWW_LOG_LEVEL ? 'debug' : 'warn'

function logDate() {
  return dayjs().format('HH:mm:ss:SSS')
}

function loggerCallback(level, args) {
  const configLevel = levels.indexOf(envLevel) || 1
  const requestLevel = levels.indexOf(level) || 1

  if (requestLevel <= configLevel) {
    console.debug(...args)
  }
}

const logger = {
  debug(...args) {
    args.unshift(logDate())
    args.unshift(chalk.greenBright('[ DEBUG ]'))
    loggerCallback('debug', args)
  },
  info(...args) {
    args.unshift(logDate())
    args.unshift(chalk.blue('[ INFO* ]'))
    loggerCallback('info', args)
  },
  warn(...args) {
    args.unshift(logDate())
    args.unshift(chalk.bgHex('#FFA500').whiteBright('[ WARN* ]'))
    loggerCallback('warn', args)
  },
  error(...args) {
    args.unshift(logDate())
    args.unshift(chalk.bgRed.whiteBright('[ ERROR ]'))
    loggerCallback('error', args)
  },
  fatal(...args) {
    args.unshift(logDate())
    args.unshift(chalk.bgRedBright.whiteBright('[ FATAL ]'))
    loggerCallback('fatal', args)
  },
}

export default logger
