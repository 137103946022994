import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Card from '@audioeye-marketing/ui/src/components/Card'
import translateSectionStyles from '@/lib/translate-section-styles'

const Quote = ({ blok, rels, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok.section_styles?.[0])
  const Wrapper = blok?.card ? Card : Box
  const wrapperProps = blok?.card
    ? {
        sx: {
          paddingLeft: { xs: 2.5, md: 5 },
          paddingRight: { xs: 2.5, md: 0 },
          paddingTop: { xs: 2.5, md: 5 },
          paddingBottom: { xs: 2.5, md: 5 },
        },
        noBorderRadius: true,
      }
    : {}

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} collapseRightPaddingMediumUp={!blok?.card}>
      {/* className necessary for color determiner in Section.jsx */}
      <Container constraint="lg" className="SectionQuote">
        <Wrapper variant="soft" {...wrapperProps}>
          <Container constraint="lg">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 5, md: 10 }}>
              <Stack spacing={2} justifyContent="space-between">
                <Stack spacing={2}>
                  <Typography level="display-md">{`“${blok?.quote}”`}</Typography>
                  <Typography level="body-lg">{blok?.name}</Typography>
                </Stack>
                {blok?.cta?.map((currentCta) => (
                  <StoryblokServerComponent
                    key={blok?._uid}
                    blok={currentCta}
                    rels={rels}
                    backgroundColor={sectionStyles?.backgroundColor}
                  />
                ))}
              </Stack>

              {blok?.image?.map((currentImage) => (
                <StoryblokServerComponent
                  key={currentImage?._uid}
                  blok={currentImage}
                  rels={rels}
                  imagePriority={imagePriority}
                />
              ))}
            </Stack>
          </Container>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default Quote
