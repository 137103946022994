import { storyblokEditable } from '@storyblok/react/rsc'
import AspectRatio from '@audioeye-marketing/ui/src/components/AspectRatio'

const Video = ({ blok }) => {
  return (
    <AspectRatio
      variant="plain"
      ratio="16/9"
      sx={{
        width: '100%',
      }}
    >
      <iframe
        {...storyblokEditable(blok)}
        style={{ border: 'none' }}
        width="100%"
        height="100%"
        src={blok?.youtube_url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </AspectRatio>
  )
}

export default Video
