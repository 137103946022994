import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import translateSectionStyles from '@/lib/translate-section-styles'

const ColumnedContent = ({ blok, rels, headingLevel, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles}>
      <Container constraint="lg">
        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokServerComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            rels={rels}
            alignItems={blok?.left_aligned ? 'flex-start' : 'center'}
            backgroundColor={sectionStyles?.backgroundColor}
            headingLevel={headingLevel}
          />
        ))}
      </Container>
      <Container constraint="lg" mt={{ xs: 3.75, md: 7.5 }}>
        {blok?.grid?.map((currentGrid) => (
          <StoryblokServerComponent
            key={currentGrid?._uid}
            blok={currentGrid}
            rels={rels}
            backgroundColor={sectionStyles?.backgroundColor}
            imagePriority={imagePriority}
          />
        ))}
      </Container>
    </Section>
  )
}

export default ColumnedContent
