import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import colors from '@audioeye-marketing/ui/src/theme/colors'

const UtilityBar = ({ blok }) => {
  return (
    <Stack
      {...storyblokEditable(blok)}
      sx={{
        backgroundColor: colors.neutral[100],
        padding: { xs: '0.25rem 1rem', md: '0.25rem 5rem' },
      }}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={0.5}
    >
      {blok?.items?.map((item) => {
        return <StoryblokServerComponent key={item?._uid} blok={item} size="sm" color="neutral" />
      })}
    </Stack>
  )
}

export default UtilityBar
