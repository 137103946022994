/**
 * * https://www.figma.com/design/jSpJcZIZcDdlpX194qUwuH/%F0%9F%95%B8%EF%B8%8F-Marketing-Website-Components?node-id=437-24108&node-type=section&t=KpSVIzQg6Mv58w3C-0
 * @param {string} padding - a string that is either sm, md, or lg to represent the amount of padding needed
 * @param {boolean} collapseTopPadding - determines whether to collapse the top padding to zero
 * @param {boolean} collapseBottomPadding - determines whether to collapse the bottom padding to zero
 * @param {boolean} collapseLeftPadding - determines whether to collapse the left padding to zero
 * @param {boolean} collapseRightPadding - determines whether to collapse the right padding to zero
 * @param {boolean} collapseRightPaddingMediumUp - determines whether to collapse the right padding to zero for large screens
 * @return {object} - object containing the padding values specific to Joy UI numbers
 */
const determinePadding = ({
  padding,
  collapseTopPadding,
  collapseBottomPadding,
  collapseLeftPadding,
  collapseRightPadding,
  collapseRightPaddingMediumUp,
}) => {
  const sizes = {
    default: {
      topBottom: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
      },
      leftRight: {
        xs: 3,
        sm: 8,
        md: 10,
        lg: '120px',
      },
    },
    sm: {
      topBottom: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
      },
      leftRight: {
        xs: 2,
        sm: 8,
        md: 10,
        lg: '120px',
      },
    },
    md: {
      topBottom: {
        xs: 4,
        sm: 5,
        md: 8,
        xl: 8,
      },
      leftRight: {
        xs: 3,
        sm: 8,
        md: 10,
        lg: '120px',
      },
    },
    lg: {
      topBottom: {
        xs: 6,
        sm: 8,
        md: 10,
        xl: 10,
      },
      leftRight: {
        xs: 3,
        sm: 8,
        md: 10,
        lg: '120px',
      },
    },
  }

  const topBottomPadding = sizes[padding]?.topBottom || sizes.lg.topBottom
  const leftRightPadding = sizes[padding]?.leftRight || sizes.default.leftRight
  const leftPadding = { ...leftRightPadding }
  const rightPadding = { ...leftRightPadding }

  if (collapseRightPaddingMediumUp) {
    rightPadding.lg = 0
    rightPadding.md = 0
  }

  return {
    paddingTop: collapseTopPadding ? 0 : topBottomPadding,
    paddingBottom: collapseBottomPadding ? 0 : topBottomPadding,
    paddingLeft: collapseLeftPadding ? 0 : leftPadding,
    paddingRight: collapseRightPadding ? 0 : rightPadding,
  }
}

export default determinePadding
